import {SET_USER, UPDATE_USER, UserActionTypes, UserState} from "../types/user-data-types";

const initialState: UserState = {
    loading: true,
    userData: null,
    isAuthenticated: false,
    role: []
};

export function userDataReducer (state = initialState, action: UserActionTypes): UserState {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                userData: action.payload.userData,
                isAuthenticated: action.payload.user!==null,
                loading: false,
                role: action.payload.role ?? []
            };
        case UPDATE_USER:
            return {
                ...state,
                role: action.payload.role ?? [],
                userData: action.payload.userData
            };

        default:
            return state
    }
}

export default userDataReducer;