import Card from "../../../../components/cards/card";
import React from "react";
import {UserData} from "../../../../models/user-data";
import {formatCurrency} from "../../../../utils/others";

const CustomerSnippet = ({customer}: {customer: UserData}) => {

    return (
        <Card className={"overflow-visible space-x-4"} style={{ padding: "0px" }}>
            <div className={"flex flex-col sm:flex-row items-start sm:items-center"}>
                <div className={`appearance-none relative block h-20 sm:h-24 flex flex-grow flex-col justify-center items-start sm:items-center pl-6 sm:pl-0`}>
                    <p className={`text-2xl font-medium`}>
                        {formatCurrency(customer.orderTotal ?? 0)}
                    </p>
                    <p className={"text-sm font opacity-60"}>
                        Amount Spent
                    </p>

                </div>
                <div className={"w-full sm:w-px h-px sm:h-20 bg-gray-200"}/>
                <div className={`appearance-none relative block h-20 sm:h-24 flex flex-grow flex-col justify-center items-start sm:items-center pl-6 sm:pl-0`}>
                    <p className={`text-2xl font-medium`}>
                        {customer?.delivered_orders}
                    </p>
                    <p className={"text-sm font opacity-60"}>
                        Orders
                    </p>

                </div>
                <div className={"w-full sm:w-px h-px sm:h-20 bg-gray-200"}/>
                <div className={`appearance-none relative block h-20 sm:h-24 flex flex-grow flex-col justify-center items-start sm:items-center pl-6 sm:pl-0`}>
                    <p className={`text-2xl font-medium`}>
                        {customer.orderTotal > 0 ? formatCurrency(((customer.orderTotal ?? 0) / (customer.delivered_orders ?? 0))) : formatCurrency(0)}
                    </p>
                    <p className={"text-sm font opacity-60"}>
                        Average order value
                    </p>

                </div>
            </div>
        </Card>
    )

}

export default CustomerSnippet;