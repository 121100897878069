import SingleSelect from "../../../../components/forms/form-single-select";
import Card from "../../../../components/cards/card";
import React, {useEffect, useState} from "react";
import {ProductItem} from "../../../../models/product-item";

const ProductStatus = ({product, onChange}: {product?: ProductItem, onChange: (value: any) => void}) => {

    const [selected, setSelected] = useState({
        name: "Select an Option"
    });

    useEffect(() => {
        let v: string;
        if (product?.disabled)
            v = "Disabled";
        else if (product?.out_of_stock)
            v = "Out of Stock";
        else
            v = "Active"

        setSelected({name: v})
    }, [product]);

    const handleChange = (val:any ) => {
        setSelected(val)
        switch (val.name) {
            case "Active":
                onChange({
                    out_of_stock: false,
                    disabled: false
                });
                break;
            case "Disabled":
                onChange({
                    disabled: true,
                    out_of_stock: false
                });
                break;
            case "Out of Stock":
                onChange({
                    out_of_stock: true,
                    disabled: false
                });
                break;
        }
    }

    return (
        <Card className={"bg-gray-50 overflow-visible space-y-4"}>
            <div className={"text-xl font-semibold max-w-sm"}>
                Product Status
            </div>
            <SingleSelect
                selectedValue={selected}
                setSelected={(val) => {
                    handleChange(val);
                }}
                options={[
                    {
                        name: "Active"
                    },
                    {
                        name: "Disabled"
                    },
                    {
                        name: "Out of Stock"
                    }
                ]}
            />
            {
                selected.name === "Disabled" &&
                    <div className={"text-sm opacity-80 mt-2 max-w-sm"}>
                        This product will be hidden from all catalogues.
                    </div>
            }
        </Card>
    )

}

export default ProductStatus;