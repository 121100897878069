// Action Types
import {SiteSettings} from "../../models/site-settings";

export const SET_SITE_SETTINGS = 'SET_SITE_SETTINGS';

interface SetSiteSettings {
    type: typeof SET_SITE_SETTINGS,
    payload: {
        settings: SiteSettings
    }
}

export type SiteSettingsActionTypes = SetSiteSettings

export interface SiteSettingState extends SiteSettings{
    loading: boolean;
}