import {
    SiteSettingsActionTypes,
    SiteSettingState,
    SET_SITE_SETTINGS
} from "../types/site-types";
import {SiteSettings} from "../../models/site-settings";

const initialState: SiteSettingState =  {
    loading: true,
    ...new SiteSettings()
};

export function conferenceReducer (state = initialState, action: SiteSettingsActionTypes): SiteSettingState {

    switch (action.type) {
        case SET_SITE_SETTINGS:
            return {
                loading: false,
                ...action.payload.settings
            };
        default:
            return state
    }
}

export default conferenceReducer;