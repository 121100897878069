import React from 'react';

const Card = ({children, className, style}: {children: React.ReactNode, style?: React.CSSProperties, className?: string}) => {

    return (
        <div className={`${className} relative bg-white box-border border border-gray-200 rounded-xl overflow-hidden px-6 py-6 shadow-sm`} style={style}>
            {children}
        </div>
    );

}

export default Card;